<template>
  <div>
    <spinner-loader :loadingStuff="loadingStuff" />
    <app-template />
    <div class="uk-container uk-container-large">
      <h2>Trialists</h2>

      <ul class="tabs">
          <li class="tab-item">
            <a
              :class="
                selectedTab === 'trialist-tab'
                  ? 'tab-link active'
                  : 'tab-link'
              "
              @click="tabChange('selectedTab', 'trialist-tab')"
              href="javascript:;"
              >Trialist</a
            >
          </li>
          <li class="tab-item">
            <a
              :class="
                selectedTab === 'leads-tab'
                  ? 'tab-link active'
                  : 'tab-link'
              "
              @click="tabChange('selectedTab', 'leads-tab')"
              href="javascript:;"
              >Player leads</a
            >
          </li>

          <li class="tab-item">
            <a
              :class="
                selectedTab === 'signed-tab'
                  ? 'tab-link active'
                  : 'tab-link'
              "
              @click="tabChange('selectedTab', 'signed-tab')"
              href="javascript:;"
              >Signed</a
            >
          </li>
          <li class="tab-item">
            <a
              :class="
                selectedTab === 'archived-tab'
                  ? 'tab-link active'
                  : 'tab-link'
              "
              @click="tabChange('selectedTab', 'archived-tab')"
              href="javascript:;"
              >Archived</a
            >
          </li>
          </ul>

      <template>
        <div class="tabs-wrapper">

          <div
              class="current-week-tab leads-tab tab-content"
              v-if="selectedTab === 'leads-tab'"
            >

            <div class="add-new-lead-wrapper">
              <button
                @click="openLeadsModal('new')"
                class="green-button add-new-lead"
              >
                Add Player lead
              </button>
            </div>
           


              <div class="table-wrapper">

                <table class="w-a-style homework-table" style="margin-bottom:2rem;">
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Player</th>
                                <th>Age group</th>
                                <th>Status</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="playerLead in allPlayerLeadsData"
                                :key="playerLead.id"
                              >
                                <td>{{ formatDateForHuman(playerLead.date) }}</td>
                                <td>{{ playerLead.name }}</td>
                                <td>{{ playerLead.age_group }}</td>
                                <td>{{ playerLead.state }}</td>
                                <td>
                                  <div class="player-game-responce">
                                    <b-button
                                      variant="outline-warning"
                                      @click="openLeadsModal('edit', playerLead)"
                                      ><b-icon
                                        icon="pencil"
                                        aria-hidden="true"
                                      ></b-icon>
                                      Edit</b-button
                                    >
                                    <b-button
                                    @click="removePlayerLead(playerLead.id)"
                                      variant="outline-danger"
                                      ><b-icon
                                        icon="trash"
                                        aria-hidden="true"
                                      ></b-icon>
                                      Delete</b-button
                                    >

                                    <b-button
                                      @click="viewLeadModal(playerLead)"
                                      variant="outline-success"
                                      ><b-icon
                                        icon="eye"
                                        aria-hidden="true"
                                      ></b-icon>
                                      View</b-button
                                    >
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>


              </div>

            </div>
             <div
              class="current-week-tab trialist-tab tab-content"
              v-if="selectedTab === 'trialist-tab'"
            >

            <div style="width:100%;">
                <button
                @click="addEditTrialistModel()"
                v-if="userData.userType === 'Admin' || userData.userType === 'Coach'"
                class="green-button add-new-programme"
              >
                Add trialist
              </button>
            </div>

            <template>
          <section
            class="player-profile-wrapper"
            v-if="liveTrialistsData.length > 0"
          >
            <div
              v-for="trialist in liveTrialistsData"
              :value="trialist.id"
              :key="trialist.id"
              class="player-profile-details"
            >
              <div class="user-photo-info">
                <img
                  v-if="
                    trialist.userImage !== '' && trialist.userImage !== null
                  "
                  :src="getImgUrl(trialist.userImage)"
                  alt=""
                />
                <img v-else :src="getImgUrl('default-image.jpg')" alt="" />
              </div>
              <div class="user-info">
                <h2>{{ trialist.name }}</h2>
                <p>Position: {{ trialist.position }}</p>
                <p>Team: {{ getTeamName(trialist.teamId) }}</p>
                <p>Trial length: {{ trialLength(trialist.trial_length) }}</p>
                <p>Trial time left: {{ getTimeLeftInTrial(trialist.trial_start_date) }}</p>

                <p>Trial score: {{ totalScore(trialist) }} / 30</p>
                <p>Coach: {{ trialist.coach_name }}</p>
                <p :class="`outCome-${outComeText(trialist.outcome)}`">{{ outComeText(trialist.outcome) }}</p>
              </div>

              <div class="player-game-responce">

              <b-button
                  variant="outline-warning"
                  v-b-tooltip.hover
                  title="Edit"
                  @click="selectedUseChanged(trialist)"
                  ><b-icon icon="pencil" aria-hidden="true"></b-icon
                ></b-button>

                    <b-button
                @click="removeTrialistFromDB(trialist.id)"
                variant="outline-danger"
                v-b-tooltip.hover
                        title="Delete trialist"
                ><b-icon icon="trash" aria-hidden="true"></b-icon></b-button
              >
              </div>
            </div>
          </section>

          <div class="empty-state" v-else>
            <img src="../img/emptyStateIllustration.svg" />
            <p>Please select a trialist to view their information</p>
          </div>
        </template>
          </div>


          <div
              class="current-week-tab trialist-tab tab-content"
              v-if="selectedTab === 'signed-tab'"
            >

            <template>
          <section
            class="player-profile-wrapper"
            v-if="signedtrialistsData.length > 0"
          >
            <div
              v-for="trialist in signedtrialistsData"
              :value="trialist.id"
              :key="trialist.id"
              class="player-profile-details"
            >
              <div class="user-photo-info">
                <img
                  v-if="
                    trialist.userImage !== '' && trialist.userImage !== null
                  "
                  :src="getImgUrl(trialist.userImage)"
                  alt=""
                />
                <img v-else :src="getImgUrl('default-image.jpg')" alt="" />
              </div>
              <div class="user-info">
                <h2>{{ trialist.name }}</h2>
                <p>Position: {{ trialist.position }}</p>
                <p>Team: {{ getTeamName(trialist.teamId) }}</p>
                <p>Trial length: {{ trialLength(trialist.trial_length) }}</p>
                <p>Trial time left: {{ getTimeLeftInTrial(trialist.trial_start_date) }}</p>

                <p>Trial score: {{ totalScore(trialist) }} / 30</p>
                <p>Coach: {{ trialist.coach_name }}</p>
                <p :class="`outCome-${outComeText(trialist.outcome)}`">{{ outComeText(trialist.outcome) }}</p>
              </div>

              <div class="player-game-responce">

              <b-button
                  variant="outline-warning"
                  v-b-tooltip.hover
                  title="Edit"
                  @click="selectedUseChanged(trialist)"
                  ><b-icon icon="pencil" aria-hidden="true"></b-icon
                ></b-button>

                    <b-button
                @click="removeTrialistFromDB(trialist.id)"
                variant="outline-danger"
                v-b-tooltip.hover
                        title="Delete trialist"
                ><b-icon icon="trash" aria-hidden="true"></b-icon></b-button
              >
              </div>
            </div>
          </section>

          <div class="empty-state" v-else>
            <img src="../img/emptyStateIllustration.svg" />
            <p>Please select a trialist to view their information</p>
          </div>
        </template>
          
          
          </div>


          <div
              class="current-week-tab trialist-tab tab-content"
              v-if="selectedTab === 'archived-tab'"
            >

            <template>
          <section
            class="player-profile-wrapper"
            v-if="archivedtrialistsData.length > 0"
          >
            <div
              v-for="trialist in archivedtrialistsData"
              :value="trialist.id"
              :key="trialist.id"
              class="player-profile-details"
            >
              <div class="user-photo-info">
                <img
                  v-if="
                    trialist.userImage !== '' && trialist.userImage !== null
                  "
                  :src="getImgUrl(trialist.userImage)"
                  alt=""
                />
                <img v-else :src="getImgUrl('default-image.jpg')" alt="" />
              </div>
              <div class="user-info">
                <h2>{{ trialist.name }}</h2>
                <p>Position: {{ trialist.position }}</p>
                <p>Team: {{ getTeamName(trialist.teamId) }}</p>
                <p>Trial length: {{ trialLength(trialist.trial_length) }}</p>
                <p>Trial time left: {{ getTimeLeftInTrial(trialist.trial_start_date) }}</p>

                <p>Trial score: {{ totalScore(trialist) }} / 30</p>
                <p>Coach: {{ trialist.coach_name }}</p>
                <p :class="`outCome-${outComeText(trialist.outcome)}`">{{ outComeText(trialist.outcome) }}</p>
              </div>

              <div class="player-game-responce">

              <b-button
                  variant="outline-warning"
                  v-b-tooltip.hover
                  title="Edit"
                  @click="selectedUseChanged(trialist)"
                  ><b-icon icon="pencil" aria-hidden="true"></b-icon
                ></b-button>

                    <b-button
                @click="removeTrialistFromDB(trialist.id)"
                variant="outline-danger"
                v-b-tooltip.hover
                        title="Delete trialist"
                ><b-icon icon="trash" aria-hidden="true"></b-icon></b-button
              >
              </div>
            </div>
          </section>

          <div class="empty-state" v-else>
            <img src="../img/emptyStateIllustration.svg" />
            <p>Please select a trialist to view their information</p>
          </div>
        </template>
          
          </div>



        </div>
      </template>
    </div>
    <b-modal
      id="viewLead"
      size="lg"
      title="Lead information"
      :hide-footer="Boolean(true)"
    >

      <div class="form lead-form-wrapper">

        <div class="form__item">
          <label class="form__label" for="name">Player name</label>
          <p>{{selectedLeadData.name}}</p>
        </div>

        <div class="form__item">
          <label class="form__label" for="name">Club name</label>
          <p>{{selectedLeadData.club_name}}</p>
        </div>

        <div class="form__item">
          <label class="form__label" for="name">Age group</label>
          <p>{{selectedLeadData.age_group}}</p>
        </div>

        <div class="form__item">
          <label class="form__label" for="name">Position</label>
          <p>{{selectedLeadData.playing_position}}</p>
        </div>

        <div class="form__item">
          <label class="form__label" for="name">Status</label>
          <p>{{selectedLeadData.state}}</p>
        </div>


        <div class="form__item">
          <label class="form__label" for="name">Scout name</label>
          <p>{{selectedLeadData.scout_name}}</p>
        </div>


        <div class="form__item">
          <label class="form__label" for="name">Scout notes / videos</label>
          <p v-html="selectedLeadData.notes"></p>
        </div>

      </div>

  </b-modal>

    <b-modal
      id="addEditLead"
      size="lg"
      :title="editMode === false ? 'Add new lead' : 'Edit lead details'"
      :hide-footer="Boolean(true)"
    >

      <div class="form lead-form-wrapper">

        <div class="form__item">
          <label class="form__label" for="name">Player name</label>
          <input
            class="form__input"
            type="text"
            v-model="selectedLeadData.name"
            id="name"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="name">Club name</label>
          <input
            class="form__input"
            type="text"
            v-model="selectedLeadData.club_name"
            id="name"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="name">Age group</label>

            <datepicker 
            v-model="selectedLeadData.age_group"
            :format="DatePickerFormat"
            minimum-view="year"              
            name="datepicker"
            id="input-id"
            input-class="input-class">
          </datepicker>

        </div>

        <div class="form__item">
          <label class="form__label" for="position">Position</label>
          <select id="position" v-model="selectedLeadData.playing_position">
            <option disabled value="">Please select a position</option>
            <option value="Goalkeeper">Goalkeeper</option>
            <option value="Right Full-back (Wingback)">
              Right Full-back (Wingback)
            </option>
            <option value="Left Full-back (Wingback)">
              Left Full-back (Wingback)
            </option>
            <option value="Center-back left">Center-back left</option>
            <option value="Center-back right">Center-back right</option>
            <option value="Defensive Midfielder">Defensive Midfielder</option>
            <option value="Centre midfield">Centre midfield</option>
            <option value="Centre Defensive Midfield">Centre Defensive Midfield</option>
            <option value="Right Midfielder (Winger)">
              Right Midfielder (Winger)
            </option>
            <option value="Left Midfielder (Winger)">
              Left Midfielder (Winger)
            </option>
            <option value="Attacking Midfielder">Attacking Midfielder</option>
            <option value="Center Forward (Striker) left">
              Center Forward (Striker) left
            </option>
            <option value="Center Forward (Striker) right">
              Center Forward (Striker) right
            </option>
            <option value="Plays in more than one position">
              Play's in more than one position
            </option>
          </select>
        </div>


        <div class="form__item">
          <label class="form__label" for="position">Status</label>
          <select id="position" v-model="selectedLeadData.state">
            <option disabled value="">Please select a state</option>
            <option value="Still to Watch">Still to Watch</option>
            <option value="Invited for trial">Invited for trial</option>
            <option value="Need to watch again">Need to watch again</option>
            <option value="Not at the standard required">Not at the standard required</option>
          </select>
        </div>


        <div class="form__item">
          <label class="form__label" for="name">Scout name</label>
          <input
            class="form__input"
            type="text"
            v-model="selectedLeadData.scout_name"
            id="name"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="name">Scout contact</label>
          <input
            class="form__input"
            type="text"
            v-model="selectedLeadData.scout_contact"
            id="name"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="name">Scout notes / videos</label>

        <wysiwyg v-model="selectedLeadData.notes" />
        </div>

        <div class="form__item" v-if="editMode === false">
          <button 
          @click="addNewPlayerLead()"
          type="submit" 
          class="green-button">Add player lead</button>
        </div>

        <div class="form__item" v-else>
          <button 
          @click="editPlayerLead()"
          type="submit" 
          class="green-button">Edit player lead</button>
        </div>

      </div>

  </b-modal>
   <b-modal
      id="addEditTrialist"
      size="lg"
      :title="editMode === false ? 'Add new trialist' : 'Edit trialist details'"
      :hide-footer="Boolean(true)"
      @hide="resetSelectedCoach()"
    >
      <form class="form" @submit.prevent="addEditCoachDetails()">
        <div class="form__item">
          <label class="form__label" for="name">Name</label>
          <input
            class="form__input"
            type="text"
            v-model="selectedTrialistsData.name"
            id="name"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="phone">phone</label>
          <input
            type="number"
            v-model="selectedTrialistsData.phone"
            id="phone"
          />
        </div>

        <div class="form__item">
            <label class="form__label" for="email">Email Address</label>
            <input
              :class="
                validationValues.email.state === true
                  ? 'danger-outline form__input'
                  : 'form__input'
              "
              @change="checkChangeVlaidation(selectedTrialistsData.email, 'email')"
              type="email"
              v-model="selectedTrialistsData.email"
              id="email"
            />
            <p
              class="invalid-feedback"
              v-if="validationValues.email.state === true"
            >
              {{ validationValues.email.text }}
            </p>
          </div>

        <div class="form__item">
            <label class="form__label" for="password">Password</label>
              <input
                type="password"
                v-model="selectedTrialistsData.password"
                id="password"
              />
          </div>

        <div class="form__item uk-form dob">
          <label class="form__label" for="dob">DOB</label>
          <datepicker v-model="selectedTrialistsData.dob" name="dob" id="dob" />
        </div>

        <ImageUploader
          @populateImageObjectFromUpload="imageUploadFileDataChnged($event)"
          @populateImageFileDataFromUpload="
            imageUploadObjectDataChanged($event)
          "
          @clearImageDataFromUpload="clearImageDataFromUpload()"
          :imageURL="selectedTrialistsData.userImage"
        />

        <div class="form__item">
          <label class="form__label" for="position">Position</label>
          <select id="position" v-model="selectedTrialistsData.position">
            <option disabled value="">Please select a position</option>
            <option value="Goalkeeper">Goalkeeper</option>
            <option value="Right Full-back (Wingback)">
              Right Full-back (Wingback)
            </option>
            <option value="Left Full-back (Wingback)">
              Left Full-back (Wingback)
            </option>
            <option value="Center-back left">Center-back left</option>
            <option value="Center-back right">Center-back right</option>
            <option value="Defensive Midfielder">Defensive Midfielder</option>
            <option value="Centre midfield">Centre midfield</option>
            <option value="Centre Defensive Midfield">Centre Defensive Midfield</option>
            <option value="Right Midfielder (Winger)">
              Right Midfielder (Winger)
            </option>
            <option value="Left Midfielder (Winger)">
              Left Midfielder (Winger)
            </option>
            <option value="Attacking Midfielder">Attacking Midfielder</option>
            <option value="Center Forward (Striker) left">
              Center Forward (Striker) left
            </option>
            <option value="Center Forward (Striker) right">
              Center Forward (Striker) right
            </option>
            <option value="I played more than one position">
              I played more than one position
            </option>
          </select>
        </div>

        <div class="form__item">
            <label class="form__label" for="coachSelect">Select team</label>
            <select v-model="selectedTrialistsData.teamId" id="coachSelect">
              <option disabled value="">Please select a team</option>
              <option v-for="team in clubTeams" :value="team.id" :key="team.id">
                {{ team.teamName }}
              </option>
            </select>
          </div>

        <div class="form__item">
            <label class="form__label" for="coachSelect">Select coach</label>
            <select v-model="selectedTrialistsData.coach_id" id="coachSelect">
              <option disabled value="">Please select a coach</option>
              <option
                v-for="coach in coachData"
                :value="coach.id"
                :key="coach.id"
              >
                {{ coach.name }}
              </option>
            </select>
          </div>


          <div class="form__item uk-form address">
            <label class="form__label" for="address">Address</label>

            <b-form-textarea
              id="address"
              v-model="selectedTrialistsData.address"
              placeholder=""
              rows="8"
            ></b-form-textarea>
          </div>

          <div class="form__item uk-form post_code">
            <label class="form__label" for="post_code">Post code</label>

            <input
              class="form__input"
              type="text"
              v-model="selectedTrialistsData.post_code"
              id="post_code"
            />
          </div>

          <div class="form__item uk-form place_of_birth">
            <label class="form__label" for="place_of_birth">Place of birth</label>

            <input
              class="form__input"
              type="text"
              v-model="selectedTrialistsData.place_of_birth"
              id="place_of_birth"
            />
          </div>

          <div class="form__item uk-form place_of_birth">
            <label class="form__label" for="country_of_birth">Country of birth</label>

            <input
              class="form__input"
              type="text"
              v-model="selectedTrialistsData.country_of_birth"
              id="place_of_birth"
            />
          </div>

          <div class="form__item uk-form nationality">
            <label class="form__label" for="nationality">Nationality</label>

            <input
              class="form__input"
              type="text"
              v-model="selectedTrialistsData.nationality"
              id="nationality"
            />
          </div>

          <div class="form__item uk-form previous_club">
            <label class="form__label" for="nationality">Previous club</label>

            <input
              class="form__input"
              type="text"
              v-model="selectedTrialistsData.previous_club"
              id="previous_club"
            />
          </div>

          <div class="form__item">
          <label class="form__label" for="parent_name"
            >Parent name</label
          >
          <input
            class="form__input"
            type="text"
            v-model="selectedTrialistsData.parent_name"
            id="parent_name"
          />
        </div>

          <div class="form__item">
            <label class="form__label" for="emergency_contact_one"
              >Emergency Contact</label
            >
            <input
              class="form__input"
              type="text"
              v-model="selectedTrialistsData.emergency_contact_one"
              id="emergency_contact_one"
            />
          </div>

          <div class="form__item">
            <label class="form__label" for="emergency_contact_two"
              >Emergency Contact two</label
            >
            <input
              class="form__input"
              type="text"
              v-model="selectedTrialistsData.emergency_contact_two"
              id="emergency_contact_two"
            />
          </div>

          <div class="form__item">
            <label class="form__label" for="position">Permission</label>
            <b-form-checkbox
              id="checkbox-1"
              v-model="selectedTrialistsData.permission"
              name="checkbox-1"
              >
              Permission to take photographs 
              </b-form-checkbox>
            </div>

            <div class="form__item">
            <label class="form__label" for="position">Medical information</label>

            <b-form-textarea
              id="textarea"
              v-model="selectedTrialistsData.medical"
              placeholder=""
              rows="8"
            ></b-form-textarea>
            </div>


        <div class="form__item">
          <label class="form__label" for="coachSelect"
            >Select trial length</label
          >
          <select
            v-model="selectedTrialistsData.trial_length"
            id="trialLengthSelect"
          >
            <option disabled value="">Please select a trial length</option>
            <option value="6">6 weeks</option>
            <option value="12">12 weeks</option>
          </select>
        </div>

        <div class="form__item uk-form dob">
          <label class="form__label" for="trialStartDate"
            >Trial start date</label
          >
          <datepicker
            v-model="selectedTrialistsData.trial_start_date"
            name="trialStartDate"
            id="trialStartDate"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="preferredFootSelect"
            >Select preferred foot</label
          >
          <select
            v-model="selectedTrialistsData.preferred_foot"
            id="preferredFootSelect"
          >
            <option disabled value="">Please select a preferred foot</option>
            <option disabled value="Right">Right</option>
            <option disabled value="Left">Left</option>
            <option disabled value="Both">Both</option>
          </select>
        </div>

        <div class="form__item">
          <button type="submit" class="green-button">Add trialist</button>
        </div>
      </form>
    </b-modal>

    <b-modal
      id="ViewTrialist"
      size="lg"
      title="Trialist performance review"
      :hide-footer="Boolean(true)"
      @hide="resetSelectedCoach()"
    >
        <div class="two-col-pop">
          <div class="user-photo-info">
            <img
              v-if="
                selectedTrialistsData.userImage !== '' &&
                selectedTrialistsData.userImage !== null
              "
              :src="getImgUrl(selectedTrialistsData.userImage)"
              alt=""
            />
            <img v-else :src="getImgUrl('default-image.jpg')" alt="" />
          </div>
          <div class="user-details-pop">
            <p><span>Name:</span> {{ selectedTrialistsData.name }}</p>
            <p><span>Phone:</span> {{ selectedTrialistsData.phone }}</p>
            <p><span>Email:</span> {{ selectedTrialistsData.email }}</p>
            <p>
              <span>DOB:</span>
              {{ formatDateForHuman(selectedTrialistsData.dob) }}
            </p>
            <p><span>Position:</span> {{ selectedTrialistsData.position }}</p>
            <p>
              <span>Trial length:</span>
              {{ trialLength(selectedTrialistsData.trial_length) }}
            </p>
            <p>
              <span>Trial start date:</span>
              {{ formatDateForHuman(selectedTrialistsData.trial_start_date) }}
            </p>
            <p>
              <span>Preferred foot:</span>
              {{ selectedTrialistsData.preferred_foot }}
            </p>

            <p><span>Team:</span> {{ getTeamName(selectedTrialistsData.teamId) }}</p>

            <p>
              <span>Coach:</span> 
              {{ selectedTrialistsData.coach_name }}</p>
          </div>
        </div>

        <h4>Performance review</h4>

        <div class="form__item">
          <label class="form__label" for="attitude">Attitude</label>
          <div class="ratingStars">
            <star-rating v-model="selectedTrialistsData.attitude"></star-rating>
          </div>
        </div>

        <div class="form__item">
          <label class="form__label" for="skill">Skill</label>
          <div class="ratingStars">
            <star-rating v-model="selectedTrialistsData.skill"></star-rating>
          </div>
        </div>

        <div class="form__item">
          <label class="form__label" for="physique">Physique</label>
          <div class="ratingStars">
            <star-rating v-model="selectedTrialistsData.physique"></star-rating>
          </div>
        </div>

        <div class="form__item">
          <label class="form__label" for="physique">Mentality</label>
          <div class="ratingStars">
            <star-rating
              v-model="selectedTrialistsData.mentality"
            ></star-rating>
          </div>
        </div>

        <div class="form__item">
          <label class="form__label" for="physique">Leadership</label>
          <div class="ratingStars">
            <star-rating
              v-model="selectedTrialistsData.leadership"
            ></star-rating>
          </div>
        </div>

        <div class="form__item">
          <label class="form__label" for="physique">Professionalism</label>
          <div class="ratingStars">
            <star-rating
              v-model="selectedTrialistsData.professionalism"
            ></star-rating>
          </div>
        </div>

        <div class="form__item">
          <label class="form__label" for="comments">Comments</label>
          <wysiwyg v-model="selectedTrialistsData.comments" />
        </div>

        <div class="out-come-buttons">
          <button @click="recordOutcome('1')" class="green-button">Sign</button>
          <button @click="recordOutcome('2')" class="red-button">Reject</button>
          <!--<button @click="recordOutcome('3')" class="yellow-button">Waiting list</button>-->
        </div>

        <p>When you sign a player, they will be automatically registered with the team they tried out for.</p>

        <div class="form__item">
          <button @click="saveTrialistPerformanceReview()" class="green-button">Save trialist</button>
        </div>
    </b-modal>
  </div>
</template>
<script>
import SpinnerLoader from "../../components/LoadingSpinning";
import AppTemplate from "../layouts/App";
import { mapGetters } from "vuex";
import { USER_POPULATE } from "@/store/actions/user.js";
import { AUTH_SIGNUP } from "@/store/actions/auth";
import dayjs from 'dayjs';
import { Users } from "@/services/user-service.js";
import { Trialists } from "@/services/trialist-service.js";
import Datepicker from "vuejs-datepicker";
import { Teams } from "@/services/teams-service.js";
import ImageUploader from "../../components/ImageUploader";
import StarRating from "vue-star-rating";
import moment from "moment";
import removeDuplicatesMixin from "@/mixin/removeDuplicates.js";

export default {
  name: "Trialists",
  mixins: [removeDuplicatesMixin],
  components: {
    AppTemplate,
    SpinnerLoader,
    Datepicker,
    ImageUploader,
    StarRating,
  },
  data() {
    return {
      loadingStuff: false,
      selectedLeadData: {},
      allPlayerLeadsData: [],
      DatePickerFormat: 'yyyy',
      needsValidation: [],
      validationMessage: null,
      validationValues: {
        name: {
          state: false,
          text: "Please add a name please!",
        },
        email: {
          state: false,
          text: "Please add an email address",
        },
        password: {
          state: false,
          text: "Please add password please!",
        },
        dob: {
          state: false,
          text: "Please add a date of birth please!",
        },
      },
      editMode: false,
      trialistsData: [],
      liveTrialistsData: [],
      signedtrialistsData: [],
      archivedtrialistsData: [],
      coachesUsersData: [],
      selectedTab: "trialist-tab",
      teamData: [],
      clubTeams: [],
      userData: {
        name: "",
        userType: "",
      },
      userHeaderData: {
        name: "",
        userType: "",
      },
      selectedUserDataForHeader: {},
      selectedUserValue: "",
      weekSelectedNumber: "",
      selectedTrialistsData: {
        dob: "",
        trial_length: "6",
        trial_start_date: "",
        preferred_foot: "Right",
        email: "",
        name: "",
        position: "",
        attitude: 0,
        skill: 0,
        physique: 0,
        mentality: 0,
        leadership: 0,
        professionalism: 0,
        comments: "",
        outcome: "0",
        clubId: "",
        teamId: "",
        userType: "User",
      },
      imageFile: null,
      imageToBeRemoved: null,
      imageURL: "",
      image: {
        imageName: "",
        path: "",
        file: null,
      },
      registerUser: {
        img: "",
        name: "",
        email: "",
        password: "",
        dob: "",
        position: "",
        phone: "",
        emergency_contact_one: "",
        userType: "User",
        action: "register",
        teamId: 0,
        permission: 0,
        clubId: null,
        coach_id: 0,
        team_module: 1,
        preferred_foot: 0,
        joined_date: "",
        medical: "",
      },
      coachData: [],
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(["getProfile", "getCoachesUsersData"]),
  },
  created() {
    const now = moment().format("MM-DD-YYYY");
    this.weekSelectedNumber = moment(now).isoWeek();
    this.setMonToSundayWeekDates();

    const clientId = JSON.parse(localStorage.getItem("the_w_selectedClientId"));
    this.getUserByIdForHeader(clientId);
    this.getUserById(clientId).then((response) => {
      console.log(response);
      if (response === true) {
        this.getTeamsByUserId();
        this.getAllTrialists();
      }
    });
  },
  methods: {
    viewLeadModal(playerLead) {
      this.selectedLeadData = playerLead;
      this.$bvModal.show("viewLead");
    },
    openLeadsModal(type, playerLead) {
      if (type !== "edit") {
        this.selectedLeadData = {};
        this.selectedLeadData.state = "Still to Watch";
        this.editMode = false;
      } else {
        this.selectedLeadData = playerLead;
        this.editMode = true;
      }

      this.$bvModal.show("addEditLead");
    },
    async loadPlayerLeads() {
      const userData = {
        action: "loadPlayerLeads",
      };
      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.allPlayerLeadsData = responce.data;
      }

    },
    async removePlayerLead(id) {
      const userData = {
        id: id,
        action: "removePlayerLead",
      };
      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.loadPlayerLeads();
        this.$bvToast.toast("Player lead removed.", {
            title: "removed",
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
            variant: "success",
          });
        }
    },
    async editPlayerLead() {
      if (typeof this.selectedLeadData.age_group === 'object') {
        this.selectedLeadData.age_group = dayjs().format("YYYY");
      }
      const userData = {
        ...this.selectedLeadData,
        action: "editPlayerLead",
      };
      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.loadPlayerLeads();
        this.$bvModal.hide("addEditLead");
        this.$bvToast.toast("Player lead edited.", {
            title: "edited",
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
            variant: "success",
          });
        }

    },
    async addNewPlayerLead() {
      this.selectedLeadData.date = dayjs().format("YYYY-MM-DD");
      this.selectedLeadData.age_group = dayjs().format("YYYY");
      const userData = {
        ...this.selectedLeadData,
        action: "addNewPlayerLead",
      };
      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.loadPlayerLeads();
        this.$bvModal.hide("addEditLead");
        this.$bvToast.toast("New lead added.", {
            title: "added",
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
            variant: "success",
          });
        }

      

    },
    async checkIfEmailExists(value) {
      const userData = {
        email: value,
        action: "checkEmailexists",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.validationValues.email.state = true;
        this.validationValues.email.text =
          "Email address aready exits, please change it";
      } else {
        this.validationValues.email.state = false;
        this.validationValues.email.text = "Please add an email address";
      }
    },
    requiredEmailValidation(value) {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return validRegex.test(value);
    },
    checkChangeVlaidation(value, elementName) {
      if (elementName === "email") {
        if (value !== "") {
          this.checkIfEmailExists(value);
        } else {
          this.validationValues.email.state = false;
          this.validationValues.email.text = "Please add an email address";
        }
        const validated = this.requiredEmailValidation(value);
        this.validationValues[elementName].state = validated !== true;
      } else {
        JSON.stringify(value).length > 0
          ? (this.validationValues[elementName].state = false)
          : (this.validationValues[elementName].state = true);
      }
    },
    getTimeLeftInTrial(trialStartDate) {
      console.log(trialStartDate);
    },
    async populateCoachesData() {
      const coachesData = {
        clubId: this.userData.clubId,
        action: "populateCoachesData",
      };

      const responce = await Users.find(coachesData).catch((error) => {
        console.log(error);
      });

      return responce.data.length > 0 ? responce.data : [];
    },
    async signPlayer() {
      const data = {
        trialistId: this.selectedTrialistsData.id,
        action: "signTrialistPlayer",
      }

      const responce = await Users.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.saveTrialistPerformanceReview();
        this.getAllTrialists();
        this.hideModal("ViewTrialist");
        this.loadingStuff = false;
        this.$bvToast.toast("Trialist signed.", {
            title: "removed",
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
            variant: "success",
          });
      }
    },
    getCoachesName(coach_id) {
      let name = "";
      this.coachData.map((coach) => {
        if (coach.id === coach_id) {
          name = coach.name;
        }
      });
      return name;
    },
    outComeActions(value) {
      value == 1 ? this.signPlayer() : this.saveTrialistPerformanceReview();
    },
    async populateTeamData() {
      const teamData = {
        clubId: this.userData.clubId,
        action: "populateTeamData",
      };

      const responce = await Teams.find(teamData).catch((error) => {
        console.log(error);
      });

      return responce.data.length > 0 ? responce.data : [];
    },
    async removeTrialistFromDB(id) {
      const userData = {
        action: "removeTrialist",
        id: id,
      };

      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.getAllTrialists();
        this.hideModal("ViewTrialist");
        this.loadingStuff = false;
        this.$bvToast.toast("Trialist removed.", {
            title: "removed",
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
            variant: "success",
          });
      }

    },
    recordOutcome(value) {
      this.selectedTrialistsData.outcome = value;
      this.outComeActions(value);
    },
    outComeText(outCome) {
      switch (outCome) {
        case 0:
          return "Under consideration";
        case 1:
          return "Signed";
        case 2:
          return "Rejected";
        case 3:
          return "Waiting list";
      }
    },
    totalScore(trialist) {
      let values = [
        trialist.attitude,
        trialist.skill,
        trialist.physique,
        trialist.mentality,
        trialist.leadership,
        trialist.professionalism,
      ];

      var totalYears = values.reduce(function (accumulator, value) {
        return accumulator + +value;
      }, 0);

      return totalYears;
    },
    getTeamName(teamId) {
      if (teamId === "") {
        return teamId;
      }
      var selectedTeam = this.clubTeams.filter(function (team) {
        return team.id == teamId;
      });

      return selectedTeam[0].teamName;
    },
    trialLength(number) {
      if (number == "6") {
        return "6 weeks";
      }
      if (number == "12") {
        return "12 weeks";
      }
    },
    tabChange(tabValue, selectedTabValue) {
      this[tabValue] = selectedTabValue;
            if(selectedTabValue === "leads-tab") {
        this.loadPlayerLeads();
      }
    },
    getImgUrl(pic) {
      return pic !== undefined && pic !== "" ? `/images/${pic}` : "";
    },
    clearImageDataFromUpload() {
      (this.imageURL = ""),
        (this.image = {
          imageName: "",
          path: "",
          file: null,
        });
    },
    imageUploadObjectDataChanged(data) {
      this.image = data;
    },
    imageUploadFileDataChnged(data) {
      this.imageFile = data;
    },
    trialistDataconvertStringsToNumbers(trialist) {
      trialist.attitude = parseInt(trialist.attitude);
      trialist.skill = parseInt(trialist.skill);
      trialist.physique = parseInt(trialist.physique);
      trialist.mentality = parseInt(trialist.mentality);
      trialist.leadership = parseInt(trialist.leadership);
      trialist.professionalism = parseInt(trialist.professionalism);

      return trialist;
    },
    selectedUseChanged(trialist) {
      let trialistData = this.trialistDataconvertStringsToNumbers(trialist);
      this.selectedTrialistsData = trialistData;
      this.showModal("ViewTrialist");
    },
    weekSelectedChanged(number) {
      this.weekSelectedNumber = number.weekSelectedNumber;
    },
    mondaySunDatesChanged(data) {
      this.selectedWeekMonSunday = data.monToFri;
    },
    setMonToSundayWeekDates() {
      const year = JSON.stringify(moment().year());
      const monday = moment()
        .year(year)
        .isoWeek(this.weekSelectedNumber)
        .day("monday")
        .startOf("day");
      this.selectedWeekMonSunday =
        monday.format("MMM D - ") + monday.add(6, "days").format("D YYYY");
      // this.depatchMonToSundayWeekDatesInLocalStorage();
    },
    async getUserById(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.userData = responce.data[0];
        const userData = responce.data[0];
        this.$store.dispatch(USER_POPULATE, { userData });
        return true;
      }
    },
    async getUserByIdForHeader(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.userHeaderData = responce.data[0];
      }
    },
    addEditCoachDetails() {
      this.edit !== true
        ? this.addTrialistDetailsSubmit()
        : this.editTrialistDetailsSubmit();
    },
    async saveTrialistPerformanceReview() {
      this.loadingStuff = true;
      const userData = {
        action: "updateTrialist",
        ...this.selectedTrialistsData,
      };

      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      this.getAllTrialists();
      this.hideModal("ViewTrialist");
      this.loadingStuff = false;
      this.$bvToast.toast("Trialist updated.", {
          title: "Updated",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
    },
    filterTrialistsIntoCategories() {
      this.liveTrialistsData = this.trialistsData.filter(trialists => trialists.outcome === 0);
      this.signedtrialistsData = this.trialistsData.filter(trialists => trialists.outcome === 1);
      this.archivedtrialistsData = this.trialistsData.filter(trialists => trialists.outcome === 2);
    },
    async getAllTrialists() {
      const userData = {
        action: "getTrialists",
        clubId: this.userData.clubId,
      };

      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.trialistsData = responce.data;
      }

      this.filterTrialistsIntoCategories();

      this.coachData = await this.populateCoachesData();
      this.teamData = await this.populateTeamData();
    },
    async getTeamsByUserId() {
      const data = {
        action: "getTeamsDataById",
        userId: this.userData.id,
      };

      if (
        this.userData.userType === "Admin" ||
        this.userData.userType === "Coach"
      ) {
        data.clubId = this.userData.clubId;
      }
      const responce = await Teams.find(data).catch((error) => {
        console.log(error);
      });
      this.clubTeams = this.removeDuplicates(responce.data, "id");
    },
    async addTrialistDetailsSubmit() {
      this.loadingStuff = true;
      if (this.image.imageFile !== null && this.imageFile !== null) {
        this.selectedTrialistsData.fileName = this.imageFile.imageName;
        this.selectedTrialistsData.imageFile = this.image;
      }
      this.selectedTrialistsData.trial_start_date = this.fotmatDatesForDb(
        this.selectedTrialistsData.dob
      );
      this.selectedTrialistsData.age_group = this.formatAgeGroupForSQL(this.selectedTrialistsData.dob);

      this.selectedTrialistsData.coach_name = this.getCoachesName(this.selectedTrialistsData.coach_id);
      this.selectedTrialistsData.clubId = this.userData.clubId;
      this.selectedTrialistsData.permission = true ? 1 : 0;
      this.selectedTrialistsData.userType = "User";
      const userData = {
        ...this.selectedTrialistsData,
        action: "addTrialist",
      };
      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.getAllTrialists();
        this.$bvModal.hide("addEditTrialist");
        this.loadingStuff = false;
        this.hideModal("ViewTrialist");
        this.$bvToast.toast("Trialist added.", {
          title: "Added",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async addEditTrialistModel(trialist) {
      if (trialist !== undefined) {
        this.selectedTrialistsData = { ...trialist };
      }
      this.showModal("addEditTrialist");
    },
    resetSelectedCoach() {
      this.selectedTrialistsData = {
        dob: "",
        trial_length: "6",
        trial_start_date: "",
        preferred_foot: "Right",
        email: "",
        name: "",
        position: "",
        attitude: 0,
        skill: 0,
        physique: 0,
        mentality: 0,
        leadership: 0,
        professionalism: 0,
        comments: "",
        outcome: "0",
        clubId: "",
        teamId: "",
      };
    },
    setHeaderUserData() {
      if (this.getProfile !== null) {
        this.userHeaderData = this.getProfile;
      }
    },
    showModal(modelId) {
      this.$bvModal.show(modelId);
    },
    hideModal(modelId) {
      this.$bvModal.hide(modelId);
    },
    fotmatDatesForDb(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    formatDateForHuman(date) {
      return moment(date).format("MMMM Do YYYY");
    },
    formatDatesForSQL(date) {
      return moment(date).format(moment.HTML5_FMT.DATE);
    },
    formatAgeGroupForSQL(dob) {
      const d = new Date(dob);
      return d.getFullYear();
    },
  },
};
</script>
<style lang="scss" scoped>
.leads-tab {
  padding:1rem;

  .add-new-lead-wrapper {
  width:100%;
  margin-bottom:1rem;
}

}

.two-col-pop {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #dee2e6;
}

span.vue-star-rating-rating-text {
  font-size: 2rem;
  padding: 0.5rem 1rem 0 1rem;
}

.user-details-pop p span {
  font-weight: bold;
  display: inline-block;
  min-width: 8rem;
}

.out-come-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;

  button {
    min-width: 9rem;
  }

  .red-button {
    background: #d85c64;
  }

  .yellow-button {
    background: #ffc107;
  }
}

.outCome-Under {
  border: 1px solid #004ca3;
  padding: 0.5rem!important;
  margin: 0.5rem 0!important;
  color: #008afc;
  background: #e8f5ff;
}

.outCome-Signed {
  border: 1px solid #18a300;
  padding: 0.5rem!important;
  margin: 0.5rem 0!important;
  color: #18a300;
  background: #ecffe8;
}

.outCome-Rejected {
  border: 1px solid #d85c64;
  padding: 0.5rem!important;
  margin: 0.5rem 0!important;
  color: #d85c64;
  background: #ffe8ec;
}

.outCome-Waiting {
  border: 1px solid #ffc107;
  padding: 0.5rem!important;
  margin: 0.5rem 0!important;
  color: #ffc107;
  background: #fffbe8;
}

.trialist-tab {
  padding:2rem;
}
</style>
